/* margins, paddings */
$hdmui-padding-margin: 0.75rem; //12px;
$hdmui-padding-margin-badge-icon: 0.6rem;
$hdmui-padding-margin-big: 1rem; //16px;
$hdmui-padding-margin-medium: 0.5rem; //8px;
$hdmui-padding-margin-small: 0.375rem; //6px;
$hdmui-padding-margin-tiny: 0.25rem; //4px;
$hdmui-padding-margin-header-footer: 0.375rem; //6px;
$hdmui-button-padding: 1.5rem; //24px;
$hdmui-link-spacing: 0 2px;
/* sizes of elements */
$hdmui-clickable-item-min-size: 3rem; //48px;
$hdmui-toolbar-height: 3.75rem; // 60px;
$hdmui-toolbar-logo-height: 3rem; //48px;
$hdmui-tab-height: 3rem; //48px;
$hdmui-list-item-height: 3rem; //48px;
$hdmui-list-two-lines-item-height: 3.75rem; //60px;
$hdmui-icon-size: 24px; //1.5rem; //24px;
$hdmui-icon-size-small: 18px; //1.125rem; //18px;
$hdmui-icon-size-large: 144px;
$hdmui-button-big: 3rem; //48px;
$hdmui-fab-button-size: 3.5rem; //56px;
$hdmui-button-regular: 2.25rem; // 36px;
$hdmui-form-element-height: 2.25rem; // 36px;
$hdmui-border-radius: 0.25rem; //4px;
$hdmui-separator-size: 1px;
$hdmui-chips-height: 32px;
$hdmui-listing-symbol-width: 12px;
$hdmui-footer-height: 3.75rem; // 60px;
$hdmui-legal-line-height: 4.5rem; //72px;
$hdmui-paginator-height: 3.75rem; // 60px;
$hdmui-progress-bar-height: 0.1875rem; // 3px;
$hdmui-style-arrow-size: 1.125rem; //18px;
$hdmui-slider-height: 3rem; //48px;
$hdmui-shadow-size: 0 0.1875rem 0.375rem; //0px 3px 6px;
$hdmui-shadow-size-reversed: 0 -0.1875rem 0.375rem; //0px -3px 6px;
$hdmui-ol-listing-symbol-width: 12px;
$hdmui-ul-listing-symbol-width: 6px;
$hdmui-banner-reverse-sticky-size: 8px;

/* opacity related variables */
$hdmui-opacity-1: 0.08;
$hdmui-opacity-2: 0.16;
$hdmui-opacity-3: 0.24;
$hdmui-opacity-4: 0.32;
$hdmui-opacity-5: 0.4;
$hdmui-opacity-6: 0.48;
$hdmui-opacity-7: 0.56;
$hdmui-opacity-8: 0.64;
$hdmui-opacity-9: 0.72;
$disabled-opacity: 0.3;
$disabled-opacity-2: 0.8;

/* other variables */
$text-tags: h1, h2, h3, h4, h5, h6, p;
