/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap.scss';

@import '@heidelberg/hdmui-angular/scss/hdmui-colors.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

html {
  overflow: hidden;
}

.mat-mdc-text-field-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.snackbar-success {
  .mdc-snackbar__surface {
    background-color: $success !important;
  }

  .mat-mdc-snack-bar-label {
    color: #ffffff !important;
  }
}

.snackbar-error {
  .mdc-snackbar__surface {
    background-color: $error !important;
  }

  .mat-mdc-snack-bar-label {
    color: #ffffff !important;
  }
}

.snackbar-warning {
  .mdc-snackbar__surface {
    background-color: $warning !important;
  }

  .mat-mdc-snack-bar-label {
    color: #ffffff !important;
  }
}

.mdc-button__label,
.mdc-floating-label {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: inline-block !important;
}

input {
  text-overflow: ellipsis;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block !important;
  text-overflow: ellipsis !important;
}

.mat-icon {
  box-sizing: content-box;
}

.mdc-list-item__primary-text,
.mdc-list-item__secondary-text {
  color: $text-primary !important;
}
