@use './hdmui-variables' as vars;

// B & W
$BL: var(--BL);
$W: var(--W);

// text
$text-primary: var(--text-primary);
$text-secondary: var(--text-secondary);
//backgrounds
$background-primary: var(--background-primary);
$background-secondary: var(--background-secondary);
$background-notification: var(--background-notification);
//accents
$link-primary: var(--link-primary);
$link-secondary: var(--link-secondary);
$highlight: var(--highlight);
$error: var(--error);
$warning: var(--warning);
$warning-strong: var(--warning-strong);
$success: var(--success);
//accents disabled
$link-primary-disabled: rgba(var(--link-primary-rgb), vars.$disabled-opacity);
$text-primary-disabled: rgba(var(--text-primary-rgb), vars.$disabled-opacity-2);
$label-text-disabled: rgba(var(--text-primary-rgb), vars.$hdmui-opacity-8);
//additional
$separator-color: rgba(var(--text-primary-rgb), vars.$hdmui-opacity-6);
$buffer-color: rgba(var(--text-primary-rgb), vars.$hdmui-opacity-3);
$box-shadow-color: var(--box-shadow-color);
$input-label-color: var(--input-label-color);

/* RGB variables to use in mixins */

// text
$text-primary-rgb: var(--text-primary-rgb);
$text-secondary-rgb: var(--text-secondary-rgb);
$text-primary-rgb: var(--text-primary-rgb);
//backgrounds
$background-primary-rgb: var(--background-primary-rgb);
$background-secondary-rgb: var(--background-secondary-rgb);
$background-notification-rgb: var(--background-notification-rgb);
//accents
$link-primary-rgb: var(--link-primary-rgb);
$link-secondary-rgb: var(--link-secondary-rgb);
$highlight-rgb: var(--highlight-rgb);
$error-rgb: var(--error-rgb);
$warning-rgb: var(--warning-rgb);
$warning-strong-rgb: var(--warning-strong-rgb);
$success-rgb: var(--success-rgb);
